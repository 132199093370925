import { createStore, compose, applyMiddleware } from 'redux'
import { offline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults'

import reducers from './reducers'
import updateDataFromServerMiddleware from './middleware/updateDataFromServerMiddleware'

// eslint-disable-next-line no-unused-vars
const logAction = store => next => action => {
    // eslint-disable-next-line no-console
    console.log(action.type)
    const result = next(action)
    return result
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
    reducers,
    {},
    composeEnhancers(
        applyMiddleware(updateDataFromServerMiddleware),
        // applyMiddleware(logAction, updateDataFromServerMiddleware),
        offline(offlineConfig)
    )
)
