import clone from 'lodash.clone'

import { SET_LIVESTOCK_DATA, SET_CURRENT_CALCULATOR_DATA } from '../actions/livestockActions'

const defaultState = {
    livestock: [],
    current: {}
}

const livestockReducer = (state = defaultState, action) => {
    const { payload } = action

    switch (action.type) {
        case SET_LIVESTOCK_DATA: {
            const cloned = clone(state)
            cloned.livestock = payload
            return cloned
        }
        case SET_CURRENT_CALCULATOR_DATA: {
            const cloned = clone(state)
            const { id, calculatorData } = payload
            cloned.current[id] = calculatorData
            return cloned
        }
        default:
            return state
    }
}

export default livestockReducer
