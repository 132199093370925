import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Layout from '../../components/Layout'

const LoadCellWiringGuide = ({ taxonomy, items }) => {
    const [manufacturer, setManufacturer] = useState('')
    const [model, setModel] = useState('')
    const [wireGuide, setWireGuide] = useState(null)

    const [manufacturerOptions, setManufacturerOptions] = useState(
        (taxonomy && taxonomy.map(p => p.manufacture)) || []
    )
    const [modelOptions, setModelOptions] = useState([])

    useEffect(() => {
        const options = (taxonomy && taxonomy.map(p => p.manufacture)) || []
        setManufacturerOptions(options)

        const first = options.length > 0 ? options[0] : ''
        setManufacturer(first)
    }, [taxonomy, items])

    useEffect(() => {
        if (!manufacturer) {
            return
        }

        const taxonomyItem = taxonomy.find(p => p.manufacture === manufacturer)
        const taxonomyModels = (taxonomyItem && taxonomyItem.models) || []
        setModelOptions(taxonomyModels)

        const first = taxonomyModels.length > 0 ? taxonomyModels[0] : ''
        setModel(first)
    }, [manufacturer])

    useEffect(() => {
        if (!manufacturer || !model) {
            return
        }

        const wireGuideItem =
            items.find(p => p.manufacture === manufacturer && p.model === model) || null
        setWireGuide(wireGuideItem)
    }, [model])

    const WireguideLookup = {
        green: {
            value: 'Green',
            className: 'green'
        },
        black: {
            value: 'Black',
            className: 'black'
        },
        red: {
            value: 'Red',
            className: 'red'
        },
        white: {
            value: 'White',
            className: null
        },
        bare: {
            value: '\u2022',
            className: 'bare'
        },
        blue: {
            value: 'Blue',
            className: 'blue'
        },
        brown: {
            value: 'Brown',
            className: 'brown'
        },
        none: {
            value: 'X',
            className: 'bare'
        },
        yellow: {
            value: 'Yellow',
            className: 'yellow'
        },
        grey: {
            value: 'Grey',
            className: 'grey'
        },
        pink: {
            value: 'Pink',
            className: 'pink'
        },
        orange: {
            value: 'Orange',
            className: 'orange'
        }
    }

    const WireguideDisplay = (label, index) => {
        if (!wireGuide) {
            return null
        }

        const itemValue = wireGuide[index]
        const info = WireguideLookup[itemValue]

        if (!info) {
            return null
        }

        const { value, className } = info
        return (
            <>
                <label htmlFor={label}>{label}</label>
                <input id={label} type="text" value={value} readOnly className={className} />
            </>
        )
    }

    return (
        <Layout>
            <div id="page-main" className="section-wrapper page-content">
                <h1>Load Cell Wiring Guide</h1>
                <p>
                    Quickly reference which wires represent signal, excitation and sense lines for
                    almost any given load cell brand and model. To download from available app
                    stores, go the ScaleTools home page.
                </p>

                <div className="col-section col-no-margin">
                    <div className="col-xs-12 col-sm-6 col-md-8">
                        <label htmlFor="manufacturers">
                            Manufacturer
                            <select
                                id="manufacturers"
                                onChange={e => {
                                    const {
                                        target: { value }
                                    } = e
                                    setManufacturer(value)
                                }}
                                value={manufacturer}
                            >
                                {manufacturerOptions.map(p => {
                                    return <option key={p}>{p}</option>
                                })}
                            </select>
                        </label>

                        <label htmlFor="models">
                            Model
                            <select
                                id="models"
                                onChange={e => {
                                    const {
                                        target: { value }
                                    } = e
                                    setModel(value)
                                }}
                                value={model}
                            >
                                {modelOptions.map(p => {
                                    return <option key={p}>{p}</option>
                                })}
                            </select>
                        </label>
                    </div>
                    {/* 
                    <p>{manufacturer}</p>
                    <p>{model}</p>
                    <p>{JSON.stringify(wireGuide)}</p> 
                    */}

                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <div id="wiring-guide">
                            <p>
                                <span>
                                    <strong>&bull;</strong> = Bare
                                </span>
                                <span>X = None</span>
                            </p>

                            {WireguideDisplay('Excitation +', 'excitationPlus')}
                            {WireguideDisplay('Excitation -', 'excitationMinus')}
                            {WireguideDisplay('Signal +', 'signalPlus')}
                            {WireguideDisplay('Signal -', 'signalMinus')}
                            {WireguideDisplay('Shield', 'shield')}
                            {WireguideDisplay('Sense +', 'sensePlus')}
                            {WireguideDisplay('Sense -', 'senseMinus')}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

function mapStateToProps(state) {
    const {
        cellWiring: { items, taxonomy }
    } = state

    return {
        taxonomy,
        items
    }
}

LoadCellWiringGuide.propTypes = {
    taxonomy: PropTypes.arrayOf(
        PropTypes.shape({
            manufacture: PropTypes.string,
            models: PropTypes.arrayOf(PropTypes.string)
        })
    ).isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            excitationMinus: PropTypes.string,
            excitationPlus: PropTypes.string,
            manufacture: PropTypes.string,
            model: PropTypes.string,
            senseMinus: PropTypes.string,
            sensePlus: PropTypes.string,
            shield: PropTypes.string,
            signalMinus: PropTypes.string,
            signalPlus: PropTypes.string
        })
    ).isRequired
}

export default connect(mapStateToProps)(LoadCellWiringGuide)
