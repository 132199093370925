import * as Yup from 'yup'

const ClassIIIValidation = Yup.object().shape({
    capacity: Yup.number()
        .required()
        .min(0),
    division: Yup.number()
        .required()
        .min(0),
    units: Yup.string().required()
})

const LivestockValidation = Yup.object().shape({
    dimension: Yup.string().required(),
    headCount: Yup.number()
        .required()
        .min(0),
    weightPerHead: Yup.number()
        .required()
        .min(0),
    pricePerPound: Yup.number()
        .required()
        .min(0),
    shrinkAtPos: Yup.number()
        .required()
        .min(0)
        .max(100),
    shrinkToMove: Yup.number()
        .required()
        .min(0)
        .max(100)
})

const MicrovoltsValidation = Yup.object().shape({
    capacity: Yup.number()
        .required()
        .min(0),
    loadCells: Yup.number()
        .required()
        .min(0),
    excitation: Yup.number()
        .required()
        .min(0),
    mvLoadCells: Yup.number()
        .required()
        .min(0),
    countBy: Yup.number()
        .required()
        .min(0)
})

export { MicrovoltsValidation, ClassIIIValidation, LivestockValidation }
