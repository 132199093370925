import clone from 'lodash.clone'

import { SET_CELL_WIRING_DATA } from '../actions/cellWiringActions'

const defaultState = {
    taxonomy: [],
    items: []
}

const GetTaxonomy = data => {
    const taxonomy = []
    const manufacturesSet = new Set([...data.map(o => o.manufacture)])
    const manufactures = new Array(...manufacturesSet)

    for (let i = 0; i < manufactures.length; i += 1) {
        const manufacture = manufactures[i]
        const itemsWithManufacture = data.filter(p => p.manufacture === manufacture)
        const modelsSet = new Set([...itemsWithManufacture.map(o => o.model)])
        const models = new Array(...modelsSet)

        taxonomy.push({
            manufacture,
            models
        })
    }

    return taxonomy
}

const cellWiringReducer = (state = defaultState, action) => {
    const { payload } = action

    switch (action.type) {
        case SET_CELL_WIRING_DATA: {
            const cloneObj = clone(state)
            cloneObj.taxonomy = null
            cloneObj.taxonomy = GetTaxonomy(payload)
            cloneObj.items = null
            cloneObj.items = payload
            return cloneObj
        }
        default:
            return state
    }
}

export default cellWiringReducer
