import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { updateDataFromServer } from './store/actions/syncActions'

import './assets/css/core.scss'

const AppDataProvider = ({ children, updateDataFromServerAction }) => {
    useEffect(() => {
        updateDataFromServerAction()
    }, [])

    return children
}

AppDataProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.object
    ]).isRequired,
    updateDataFromServerAction: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
    return {
        // setCellWiringAction: player => dispatch(setCellWiringData(player)),
        updateDataFromServerAction: () => dispatch(updateDataFromServer())
    }
}

// eslint-disable-next-line prettier/prettier
export default connect(
    null,
    mapDispatchToProps
)(AppDataProvider)
