import React from 'react'
import { PropTypes } from 'prop-types'
import { useField } from 'formik'

const ScaleToolsSelectComponent = ({ label, options, className, ...props }) => {
    const [field, meta] = useField(props)
    const { touched, error } = meta
    const { name, value } = field

    return (
        <>
            <label htmlFor={name} className={error && touched && !value ? 'error' : ''}>
                {label}
            </label>
            <select {...field} {...props}>
                {options.map(option => {
                    let optionKey = ''
                    let optionValue = ''

                    if (typeof option === 'string' || option instanceof String) {
                        // it's a string
                        optionKey = option
                        optionValue = option
                    } else {
                        // it's something else
                        const { key: objOptionKey, value: objOptionValue } = option
                        optionKey = objOptionKey
                        optionValue = objOptionValue
                    }

                    return (
                        <option key={optionKey} value={optionKey}>
                            {optionValue}
                        </option>
                    )
                })}
            </select>
        </>
    )
}

ScaleToolsSelectComponent.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                value: PropTypes.string
            })
        ])
    )
}

ScaleToolsSelectComponent.defaultProps = {
    className: null,
    options: []
}

export default ScaleToolsSelectComponent
