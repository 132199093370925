import React from 'react'
import { PropTypes } from 'prop-types'
import { useField } from 'formik'

const ScaleToolsField = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    const { touched, error } = meta

    const { name } = field
    return (
        <>
            <label htmlFor={name} className={error && touched ? 'red' : ''}>
                {label}
            </label>
            <input id={name} {...field} {...props} className={error && 'red'} />
            {/* {touched && error ? <div className="red">{error}</div> : null} */}
        </>
    )
}

ScaleToolsField.propTypes = {
    label: PropTypes.string.isRequired
}

export default ScaleToolsField
