export const UPDATE_SYNCED = 'UPDATE_SYNCED'
export const UPDATE_DATA_FROM_SERVER = 'UPDATE_DATA_FROM_SERVER'

export const updateSynced = synced => {
    return {
        type: UPDATE_SYNCED,
        payload: synced
    }
}

export const updateDataFromServer = () => {
    return {
        type: UPDATE_DATA_FROM_SERVER,
        payload: null
    }
}
