export const SET_LIVESTOCK_DATA = 'SET_LIVESTOCK_DATA'
export const SET_CURRENT_CALCULATOR_DATA = 'SET_CURRENT_CALCULATOR_DATA'

export const setLivestockData = livestockData => {
    return {
        type: SET_LIVESTOCK_DATA,
        payload: livestockData
    }
}

export const setLivestockCalculatorData = (id, calculatorData) => {
    return {
        type: SET_CURRENT_CALCULATOR_DATA,
        payload: {
            id,
            calculatorData
        }
    }
}
