import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const LivestockProductCard = ({ id, title, description, imageBase64Url, hideButton }) => (
    <Link to={`/livestock-shrink/${id}`}>
        <img src={imageBase64Url} alt="product" />
        <h2>{title}</h2>
        <p>{description}</p>
        {!hideButton && <button type="button">Start Calculating</button>}
    </Link>
)

LivestockProductCard.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageBase64Url: PropTypes.string.isRequired,
    hideButton: PropTypes.bool
}

LivestockProductCard.defaultProps = {
    hideButton: false
}

export default LivestockProductCard
