import React from 'react'
import { Link } from 'react-router-dom'

import Layout from '../../components/Layout'

const Landing = () => (
    <Layout>
        <div id="page-main" className="section-wrapper page-content">
            <h1>Rice Lake ScaleTools</h1>

            <p>
                Navigate to our web version of these apps directly using the menu at the upper left.
                Apps available for download to your device are shown below. Visit our ScaleTools
                Frequently Asked Questions for help with making these apps available on your mobile
                device anytime.
            </p>

            <div id="tool-grid" className="col-section col-no-margin">
                <div className="col-xs-12 col-sm-6">
                    <Link to="/weight-conversion">
                        <h2>Weight Unit Conversion Utility</h2>
                        <p>Determine the equivalent weight in a different unit of measure.</p>
                    </Link>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Link to="/load-cell-wiring">
                        <h2>Load Cell Wiring Guide</h2>
                        <p>
                            Quickly reference which wires represent signal, excitation and sense
                            lines for almost any given load cell brand and model.
                        </p>
                    </Link>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Link to="/uv-graduation">
                        <h2>MicroVolts Per Graduation App</h2>
                        <p>
                            Determine the appropriate µV per graduation for your scale application.
                        </p>
                    </Link>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Link to="/class-iii-tolerance">
                        <h2>Class III Tolerance App</h2>
                        <p>
                            Calculate the allowable maintenance and acceptance tolerances for Legal
                            for Trade applications.
                        </p>
                    </Link>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Link to="/livestock-shrink">
                        <h2>Livestock Shrink Calculator</h2>
                        <p>
                            Quickly calculate the savings and return on your Rice Lake Livestock
                            Scale investment.
                        </p>
                    </Link>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Link to="/motoweigh-roi">
                        <h2>MotoWeigh® Return on Investment Calculator</h2>
                        <p>
                            Calculate the number of days to see ROI on an in-motion conveyor scale
                            based on average package overfill.
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    </Layout>
)

export default Landing
