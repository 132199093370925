/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Layout from '../../../components/Layout'
import LivestockCalculate from '../LivestockCalculator'

const LivestockShrinkCalculationDetails = ({
    currentLivestock,
    currentDimension,
    headCount,
    weightPerHead,
    pricePerPound,
    shrinkAtPos,
    shrinkToMove
}) => {
    const { id } = currentLivestock
    const { priceBreakdown } = currentDimension

    const [calculation, setCalculation] = useState({ perShipment: {}, perHead: {} })

    useEffect(() => {
        const { totalPrice } = currentDimension
        setCalculation(
            LivestockCalculate({
                headCount,
                weightPerHead,
                pricePerPound,
                shrinkAtPos,
                shrinkToMove,
                totalPrice
            })
        )
    }, [])

    const {
        perShipment: {
            pos: shipmentPos,
            shipping: shipmentShipping,
            lossPerShipment,
            shipmentsToPayOff,
            savingsWithScale
        },
        perHead: { pos: headPos, shipping: headShipping, totalLossPerHead }
    } = calculation

    const NumberAsCurrency = value => (
        <>
            $&nbsp;
            {Number(value).toLocaleString(undefined, {
                maximumFractionDigits: 2
            })}
        </>
    )

    return (
        <Layout>
            <div id="page-main" className="section-wrapper page-content">
                <div id="livestock-button-header" className="col-section col-no-margin">
                    <div className="col-xs-12 col-sm-5">
                        <button type="button" onClick={() => window.print()} className="btn">
                            <img src="images/pdf-icon@2x.png" alt="" width="20" /> Generate PDF
                        </button>
                        <Link to={`/livestock-shrink/${id}`} className="btn btn-grey">
                            Back
                        </Link>
                    </div>
                    <div className="col-xs-12 col-sm-7">
                        <h1>Product Pricing Breakdown</h1>
                    </div>
                </div>

                <table className="result-table calc-table responsive-table">
                    <tbody>
                        <tr>
                            <th>PN</th>
                            <th>List</th>
                        </tr>
                        {priceBreakdown.map(p => {
                            const { id: breakdownPriceId, listPrice, pn } = p
                            return (
                                <tr key={breakdownPriceId}>
                                    <td data-th="PN">{pn}</td>

                                    <td data-th="List">{NumberAsCurrency(listPrice)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <h2 className="h1">Per Shipment Calculations</h2>
                <table className="result-table calc-table responsive-table">
                    <tbody>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td data-th="Description">Pencil Shrink at Point of Sale (lbs)</td>
                            <td data-th="List">{shipmentPos} lbs</td>
                        </tr>
                        <tr>
                            <td data-th="Description">Shipping Shrink (lbs)</td>
                            <td data-th="List">{shipmentShipping} lbs</td>
                        </tr>
                        <tr>
                            <td data-th="Description">Total Loss per Shipment</td>
                            <td data-th="List">{NumberAsCurrency(lossPerShipment)}</td>
                        </tr>
                        <tr>
                            <td data-th="Description">Shipments to Pay off Scale</td>
                            <td data-th="List">{Math.ceil(shipmentsToPayOff)}</td>
                        </tr>
                        <tr>
                            <td data-th="Description">
                                Savings per Shipment with Rice Lake Livestock Scale
                            </td>
                            <td data-th="List">{NumberAsCurrency(savingsWithScale)}</td>
                        </tr>
                    </tbody>
                </table>

                <h2 className="h1">Per Head Calculations</h2>
                <table className="result-table calc-table responsive-table">
                    <tbody>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td data-th="Description">Pencil Shrink at Point of Sale (lbs)</td>
                            <td data-th="List">{headPos} lbs</td>
                        </tr>
                        <tr>
                            <td data-th="Description">Shipping Shrink per Head (lbs)</td>
                            <td data-th="List">{headShipping} lbs</td>
                        </tr>
                        <tr>
                            <td data-th="Description">Total Loss per Head ($)</td>
                            <td data-th="List">{NumberAsCurrency(totalLossPerHead)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Layout>
    )
}

function mapStateToProps(state, ownProps) {
    const {
        livestock: { current, livestock }
    } = state

    const {
        match: {
            params: { id }
        }
    } = ownProps

    let currentLivestock = {}
    let currentDimension = { priceBreakdown: [] }
    const currentFormValues = current[id]

    const dimension = currentFormValues && currentFormValues.dimension
    if (livestock && id) {
        currentLivestock = livestock.find(o => o.id === Number(id))
        if (currentLivestock) {
            currentDimension = currentLivestock.dimensions.find(i => i.id === dimension)
        }
    }

    return {
        currentLivestock,
        currentDimension,
        ...currentFormValues
    }
}

LivestockShrinkCalculationDetails.propTypes = {
    currentLivestock: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string
    }),
    currentDimension: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
        totalPrice: PropTypes.number,
        priceBreakdown: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                listPrice: PropTypes.number,
                pn: PropTypes.string
            })
        )
    }),
    headCount: PropTypes.number,
    weightPerHead: PropTypes.number,
    pricePerPound: PropTypes.number,
    shrinkAtPos: PropTypes.number,
    shrinkToMove: PropTypes.number
}

LivestockShrinkCalculationDetails.defaultProps = {
    currentLivestock: {},
    currentDimension: { priceBreakdown: [] },
    headCount: 0,
    weightPerHead: 0,
    pricePerPound: 0,
    shrinkToMove: 0,
    shrinkAtPos: 0
}

export default connect(mapStateToProps)(LivestockShrinkCalculationDetails)
