import { combineReducers } from 'redux'

import synced from './syncReducer'
import cellWiringReducer from './cellWiringReducer'
import livestockReducer from './livestockReducer'

export default combineReducers({
    synced,
    cellWiring: cellWiringReducer,
    livestock: livestockReducer
})
