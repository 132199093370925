import { UPDATE_SYNCED } from '../actions/syncActions'

const defaultState = false

const syncReducer = (state = defaultState, action) => {
    const { payload } = action
    switch (action.type) {
        case UPDATE_SYNCED: {
            return payload
        }
        default:
            return state
    }
}

export default syncReducer
