import GetScaleToolsData from '../../apiClient'
import { UPDATE_DATA_FROM_SERVER, updateSynced } from '../actions/syncActions'
import { setCellWiringData } from '../actions/cellWiringActions'
import { setLivestockData } from '../actions/livestockActions'

const updateDataFromServerMiddleware = store => next => action => {
    if (action.type === UPDATE_DATA_FROM_SERVER) {
        store.dispatch(updateSynced(false))

        GetScaleToolsData()
            .then(data => {
                const { cellWiringData, livestockShrinkData } = data
                store.dispatch(setCellWiringData(cellWiringData))
                store.dispatch(setLivestockData(livestockShrinkData))
            })
            .catch(() => {
                // console.error('some err')
            })
            .finally(() => {
                store.dispatch(updateSynced(true))
                // console.log('finally')
            })
    }

    return next(action)
}

export default updateDataFromServerMiddleware
