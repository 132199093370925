import React from 'react'

import Layout from '../../../components/Layout'
import LivestockProductList from '../LivestockProductList'

const LivestockShrinkList = () => (
    <Layout>
        <div id="page-main" className="section-wrapper page-content">
            <h1>Livestock Shrink Calculator</h1>

            <p>
                Quickly calculate the savings and return on your Rice Lake Livestock Scale
                investment. Please select a model from the below product lines to start.
            </p>

            <LivestockProductList />
        </div>
    </Layout>
)

export default LivestockShrinkList
