import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateDataFromServer } from '../store/actions/syncActions'

import Header from './Header'
import HeaderLogo from '../assets/images/rice-lake-logo@2x.png'

const Layout = ({ children }) => (
    <>
        <Header />
        {children}

        <footer>
            <img src={HeaderLogo} width="240" alt="rice lake logo" />
        </footer>
    </>
)

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.object
    ]).isRequired
}

function mapStateToProps(state) {
    return {
        synced: state.synced
    }
}
function mapDispatchToProps(dispatch) {
    return {
        syncDataAction: () => dispatch(updateDataFromServer())
    }
}

// eslint-disable-next-line prettier/prettier
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout)
