const LivestockCalculate = ({
    headCount,
    weightPerHead,
    pricePerPound,
    shrinkAtPos,
    shrinkToMove,
    totalPrice
}) => {
    const posShrinkPercent = shrinkAtPos / 100
    const moveShrinkPercent = shrinkToMove / 100

    const shipmentPos = headCount * weightPerHead * posShrinkPercent
    const shipmentShipping = headCount * weightPerHead * moveShrinkPercent
    const lossPerShipment = (shipmentShipping - shipmentPos) * pricePerPound

    const headPos = pricePerPound * weightPerHead * posShrinkPercent
    const headShipping = pricePerPound * weightPerHead * moveShrinkPercent

    return {
        perShipment: {
            pos: shipmentPos,
            shipping: shipmentShipping,
            lossPerShipment,
            shipmentsToPayOff: totalPrice / lossPerShipment,
            savingsWithScale: lossPerShipment
        },
        perHead: {
            pos: headPos,
            shipping: headShipping,
            totalLossPerHead: headShipping - headPos
        }
    }
}

export default LivestockCalculate
