import axios from 'axios'

const API_BASE_URL = process.env.BaseApiEndpoint

const RETURN_MOCK_DATA = false
const MOCK_DATA = {
    livestock: [
        {
            id: 1,
            name: 'MAS-M',
            description:
                'The Mobile Group Animal Scales patented Design consists of a deck and pen sides, suspended on load cells at four points within a portable base frame. When in transport mode, the cage is "Locked-Down" to the frame. This prevents cage movement and load cell damage during transport.',
            imageUrl: 'http://www.fillmurray.com/448/342',
            dimensions: [
                {
                    id: 201,
                    name: '18x18',
                    scalePrice: 34999,
                    indicatorPrice: 1000,
                    printerPrice: 825,
                    shippingPrice: 1000
                },
                {
                    id: 202,
                    name: '20x20',
                    scalePrice: 44999,
                    indicatorPrice: 1500,
                    printerPrice: 825,
                    shippingPrice: 1050
                }
            ]
        },
        {
            id: 2,
            name: 'MAS-RD',
            description:
                'TEMP THINGS a portable base frame. When in transport mode, the cage is "Locked-Down" to the frame. This prevents cage movement and load cell damage during transport.',
            imageUrl: 'http://www.fillmurray.com/448/342',
            dimensions: [
                {
                    id: 201,
                    name: '14x14',
                    scalePrice: 34999,
                    indicatorPrice: 1000,
                    printerPrice: 825,
                    shippingPrice: 1000
                },
                {
                    id: 202,
                    name: '17x17',
                    scalePrice: 44999,
                    indicatorPrice: 1500,
                    printerPrice: 825,
                    shippingPrice: 1050
                }
            ]
        }
    ],
    cellWiringData: [
        {
            manufacture: 'RLWS',
            model: 'RL1010',
            excitationPlus: 'green',
            excitationMinus: 'black',
            signalPlus: 'red',
            signalMinus: 'white',
            shield: 'bare',
            sensePlus: 'blue',
            senseMinus: 'brown'
        },
        {
            manufacture: 'RLWS',
            model: 'RL1011',
            excitationPlus: 'black',
            excitationMinus: 'red',
            shield: 'bare',
            senseMinus: 'brown'
        },
        {
            manufacture: 'Drost',
            model: 'RD101',
            excitationPlus: 'green',
            excitationMinus: 'black',
            signalPlus: 'red',
            signalMinus: 'white',
            shield: 'bare',
            sensePlus: 'blue',
            senseMinus: 'brown'
        }
    ]
}

const GetScaleToolsData = () => {
    return new Promise((resolve, reject) => {
        if (RETURN_MOCK_DATA) {
            resolve(MOCK_DATA)
        } else {
            axios
                .get(`${API_BASE_URL}/umbraco/api/scaletools/index`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(ex => {
                    reject(ex.message)
                })
        }
    })
}

export default GetScaleToolsData
