/* eslint-disable */
var metric, imperial

metric = {
    mg: {
        name: {
            singular: 'Milligram',
            plural: 'Milligrams'
        },
        to_anchor: 1 / 1000
    },

    g: {
        name: {
            singular: 'Gram',
            plural: 'Grams'
        },
        to_anchor: 1
    },
    kg: {
        name: {
            singular: 'Kilogram',
            plural: 'Kilograms'
        },
        to_anchor: 1000
    },
    mt: {
        name: {
            singular: 'Metric Tonne',
            plural: 'Metric Tonnes'
        },
        to_anchor: 1000000
    }
}

imperial = {
    grain: {
        name: {
            singular: 'Grain',
            plural: 'Grains'
        },
        to_anchor: 1 / 7000
    },
    momme: {
        name: {
            singular: 'Momme',
            plural: 'Mommes'
        },
        to_anchor: 3750000 / 45359237
    },
    carat: {
        name: {
            singular: 'Carat',
            plural: 'Carats'
        },
        to_anchor: 1 / 2268
    },
    pennyweight: {
        name: {
            singular: 'Pennyweight',
            plural: 'Pennyweights'
        },
        to_anchor: 1 / 292
    },
    hwt: {
        name: {
            singular: 'Hundredweight'
        },
        to_anchor: 112
    },
    dram: {
        name: {
            singular: 'Dram',
            plural: 'Drams'
        },
        to_anchor: 1 / 256
    },
    oz: {
        name: {
            singular: 'Ounce',
            plural: 'Ounces'
        },
        to_anchor: 1 / 16
    },
    toz: {
        name: {
            singular: 'Troy Ounce',
            plural: 'Troy Ounces'
        },
        to_anchor: 1 / 14.5152
    },
    lb: {
        name: {
            singular: 'Pound',
            plural: 'Pounds'
        },
        to_anchor: 1
    },
    stone: {
        name: {
            singular: 'Stone',
            plural: 'Stones'
        },
        to_anchor: 14
    },
    quarter: {
        name: {
            singular: 'Quarter',
            plural: 'Quarters'
        },
        to_anchor: 28
    },
    shortTon: {
        name: {
            singular: 'Short Ton',
            plural: 'Short Tons'
        },
        to_anchor: 2000
    },
    longTon: {
        name: {
            singular: 'Long Ton',
            plural: 'Long Tons'
        },
        to_anchor: 2240
    }
}

module.exports = {
    metric,
    imperial,
    _anchors: {
        metric: {
            unit: 'g',
            ratio: 1 / 453.59237
        },
        imperial: {
            unit: 'lb',
            ratio: 453.59237
        }
    }
}
