import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import LivestockProductCard from './LivestockProductCard'

const LivestockProductList = ({ livestock, currentProduct }) => (
    <div id="product-grid" className="col-section col-no-margin">
        {livestock.map(item => {
            const { id } = item
            if (currentProduct === id) return null
            return (
                <div key={id} className="col-xs-12 col-sm-6 col-md-4">
                    <LivestockProductCard {...item} />
                </div>
            )
        })}
    </div>
)

function mapStateToProps(state) {
    const {
        livestock: { livestock }
    } = state

    return {
        livestock
    }
}

LivestockProductList.propTypes = {
    livestock: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            imageUrl: PropTypes.string
        })
    ).isRequired,
    currentProduct: PropTypes.number
}

LivestockProductList.defaultProps = {
    currentProduct: null
}

export default connect(mapStateToProps)(LivestockProductList)
