import React from 'react'
import ScaleToolsField from './ScaleToolsField'

const ScaleToolsTextInput = ({ ...props }) => <ScaleToolsField {...props} type="text" />
const ScaleToolsNumberInput = ({ ...props }) => (
    <ScaleToolsField {...props} type="number" min="0" step="any" />
)

export { ScaleToolsTextInput, ScaleToolsNumberInput }

// const ScaleToolsTextInput = ({ ...props }) => <ScaleToolsTextField {...props} type="text" />
// const ScaleToolsNumberInput = ({ ...props }) => (
//     <ScaleToolsFormattedField {...props} inputMode="numeric" pattern="[0-9.]*" type="text" />
// )
// const ScaleToolsEmailInput = ({ ...props }) => <ScaleToolsTextField {...props} type="email" />
// const ScaleToolsDateInput = ({ ...props }) => <ScaleToolsTextField {...props} type="date" />
// const ScaleToolsDateTimeInput = ({ ...props }) => (
//     <ScaleToolsTextField {...props} type="datetime-local" />
// )
