/* eslint-disable no-console */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { HashRouter as Router, Route } from 'react-router-dom'

import store from './store'
import AppDataProvider from './AppDataProvider'

import Landing from './pages/Landing/Landing'
import WeightUnitConversion from './pages/WeightUnitConversion'
import LoadCellWiringGuide from './pages/LoadCellWiringGuide'
import uVGraduation from './pages/uVGraduation'
import ClassIIITolerance from './pages/ClassIIITolerance'
import LivestockShrinkList from './pages/LivestockShrink/List'
import MotoweighRoi from './pages/MotoweighRoi'

import './assets/css/core.scss'
import LivestockShrinkCalculate from './pages/LivestockShrink/Calculate'
import LivestockCalculationDetails from './pages/LivestockShrink/CalculationDetails'

const App = () => (
    <Provider store={store}>
        <AppDataProvider>
            <Router>
                <Route path="/" exact component={Landing} />
                <Route path="/weight-conversion" component={WeightUnitConversion} />
                <Route path="/load-cell-wiring" component={LoadCellWiringGuide} />
                <Route path="/uv-graduation" component={uVGraduation} />
                <Route path="/class-iii-tolerance" component={ClassIIITolerance} />
                <Route path="/livestock-shrink" exact component={LivestockShrinkList} />
                <Route path="/livestock-shrink/:id" exact component={LivestockShrinkCalculate} />
                <Route
                    path="/livestock-shrink/:id/details"
                    exact
                    component={LivestockCalculationDetails}
                />
                <Route path="/motoweigh-roi" exact component={MotoweighRoi} />
            </Router>
        </AppDataProvider>
    </Provider>
)

const element = document.createElement('div')
document.body.appendChild(element)

ReactDOM.render(<App />, element)

if (process.env.ENABLE_SERVICE_WORKER) {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(registration => {
                    console.log('SW registered: ', registration)
                })
                .catch(registrationError => {
                    console.log('SW registration failed: ', registrationError)
                })
        })
    }
}
