import React, { useState, useEffect } from 'react'

import Layout from '../../components/Layout'
import convert from './UnitConversionCalculator'

const POUNDS = 'lb'
const MILLIGRAMS = 'mg'
const GRAMS = 'g'
const KILOGRAMS = 'kg'
const METRIC_TONS = 'mt'
const GRAIN = 'grain'
const HUNDREDWEIGHT = 'hwt'
const DRAM = 'dram'
const OUNCE = 'oz'
const TROY_OUNCE = 'toz'
const STONE = 'stone'
const QUARTER = 'quarter'
const SHORT_TON = 'shortTon'
const LONG_TON = 'longTon'

const PENNYWEIGHT = 'pennyweight'
const CARAT = 'carat'
const MOMME = 'momme'

const WeightUnitConversion = () => {
    const [fromUnits, setFromUnits] = useState(null)
    const [fromValue, setFromValue] = useState(null)

    const [pounds, setPounds] = useState(0)
    const [hundredweight, setHundredweight] = useState(0)
    const [longTon, setLongTon] = useState(0)
    const [shortTon, setShortTon] = useState(0)
    const [quarter, setQuarter] = useState(0)
    const [stone, setStone] = useState(0)
    const [ounce, setOunce] = useState(0)
    const [drams, setDrams] = useState(0)
    const [grain, setGrain] = useState(0)
    const [metricTon, setMetricTon] = useState(0)
    const [kilograms, setKilograms] = useState(0)
    const [gram, setGrams] = useState(0)
    const [milligrams, setMilligrams] = useState(0)
    const [troyOunce, setTroyOunce] = useState(0)
    const [pennywight, setPennywight] = useState(0)
    const [carat, setCarat] = useState(0)
    const [momme, setMomme] = useState(0)

    const RoundToDigits = 100000000
    const SigFigs = inVal => Math.round((inVal + Number.EPSILON) * RoundToDigits) / RoundToDigits

    useEffect(() => {
        const ResetAllFields = () => {
            setPounds(0)
            setHundredweight(0)
            setLongTon(0)
            setShortTon(0)
            setQuarter(0)
            setStone(0)
            setOunce(0)
            setDrams(0)
            setGrain(0)
            setMetricTon(0)
            setKilograms(0)
            setGrams(0)
            setMilligrams(0)
            setTroyOunce(0)
            setPennywight(0)
            setCarat(0)
            setMomme(0)
        }

        if (!fromUnits) {
            ResetAllFields()
            return
        }

        setPounds(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(POUNDS)
            )
        )

        setHundredweight(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(HUNDREDWEIGHT)
            )
        )
        setLongTon(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(LONG_TON)
            )
        )
        setShortTon(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(SHORT_TON)
            )
        )
        setQuarter(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(QUARTER)
            )
        )
        setStone(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(STONE)
            )
        )

        setOunce(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(OUNCE)
            )
        )
        setDrams(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(DRAM)
            )
        )
        setGrain(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(GRAIN)
            )
        )
        setMetricTon(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(METRIC_TONS)
            )
        )
        setKilograms(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(KILOGRAMS)
            )
        )
        setGrams(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(GRAMS)
            )
        )
        setMilligrams(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(MILLIGRAMS)
            )
        )
        setTroyOunce(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(TROY_OUNCE)
            )
        )
        setPennywight(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(PENNYWEIGHT)
            )
        )
        setCarat(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(CARAT)
            )
        )
        setMomme(
            SigFigs(
                convert(fromValue)
                    .from(fromUnits)
                    .to(MOMME)
            )
        )
    }, [fromUnits, fromValue])

    const ResetForm = () => {
        setFromUnits(null)
        setFromValue(0)
    }

    const RenderField = (label, unit, fieldValue) => {
        const normalizedLabel = label.toLowerCase().replace(' ', '-')

        return (
            <div className="col-xs-12 col-sm-6 col-md-4">
                <label htmlFor={normalizedLabel}>
                    {label}
                    <input
                        id={normalizedLabel}
                        value={fieldValue}
                        type="number"
                        style={{ borderColor: unit === fromUnits ? 'red' : null }}
                        step="any"
                        onChange={e => {
                            const {
                                target: { value }
                            } = e
                            let inputValue = Number(value)
                            inputValue = inputValue <= 0 ? 0 : inputValue
                            setFromValue(inputValue)
                            setFromUnits(unit)
                        }}
                    />
                </label>
            </div>
        )
    }

    return (
        <Layout>
            <div id="page-main" className="section-wrapper page-content">
                <h1>Weight Conversion Utility</h1>

                <form id="weight-conversion">
                    <div className="col-section col-no-margin">
                        {RenderField('Pounds', POUNDS, pounds)}
                        {RenderField('Hundredweight', HUNDREDWEIGHT, hundredweight)}
                        {RenderField('Long Ton', LONG_TON, longTon)}
                        {RenderField('Short Ton', SHORT_TON, shortTon)}
                        {RenderField('Quarter', QUARTER, quarter)}
                        {RenderField('Stone', STONE, stone)}
                        {RenderField('Ounce', OUNCE, ounce)}
                        {RenderField('Drams', DRAM, drams)}
                        {RenderField('Grain', GRAIN, grain)}
                        {RenderField('Metric Tonne', METRIC_TONS, metricTon)}
                        {RenderField('Kilogram', KILOGRAMS, kilograms)}
                        {RenderField('Gram', GRAMS, gram)}
                        {RenderField('Milligram', MILLIGRAMS, milligrams)}
                        {RenderField('Troy Ounce', TROY_OUNCE, troyOunce)}
                        {RenderField('Pennywight', PENNYWEIGHT, pennywight)}
                        {RenderField('Carat', CARAT, carat)}
                        {RenderField('Momme', MOMME, momme)}

                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <button type="button" onClick={ResetForm}>
                                Reset Fields
                            </button>
                        </div>
                        <div className="col-xs-12 col-md-8">
                            <p>
                                Note: Converted weights are rounded and may vary. The number of
                                divisions configured in your application may produce different
                                results.
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default WeightUnitConversion
